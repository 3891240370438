import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Motion_Detection/NavButtons';
import ForumBox from 'components/Motion_Detection/Router_as_a_FTP_Server/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Netgear ReadyNAS as FTP-Server",
  "path": "/Motion_Detection/Router_as_a_FTP_Server/Netgear_ReadyNAS_as_FTP_Server/",
  "dateChanged": "2017-11-27",
  "author": "Mike Polinowski",
  "excerpt": "Use the FTP service of your router or network drive to back up your alarm video and images.",
  "image": "MD_SearchThumb_RouterAsFTPServer_Synology.png",
  "social": "/images/Search/MD_SearchThumb_RouterAsFTPServer_Synology.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_MD-Router-FTP-Server_white.webp",
  "chapter": "Motion Detection"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Netgear ReadyNAS as FTP-Server' dateChanged='2017-11-27' author='Mike Polinowski' tag='INSTAR IP Camera' description='Use the FTP service of your router or network drive to back up your alarm video and images.' image='/images/Search/MD_SearchThumb_RouterAsFTPServer_QNAP.png' twitter='/images/Search/MD_SearchThumb_RouterAsFTPServer_QNAP.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Motion_Detection/Router_as_a_FTP_Server/Netgear_ReadyNAS_as_FTP_Server/' locationFR='/fr/Motion_Detection/Router_as_a_FTP_Server/Netgear_ReadyNAS_as_FTP_Server/' crumbLabel="Linksys" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "router-as-ftp-server",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#router-as-ftp-server",
        "aria-label": "router as ftp server permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Router as FTP Server`}</h1>
    <h2 {...{
      "id": "netgear-readynas-as-ftp-server",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#netgear-readynas-as-ftp-server",
        "aria-label": "netgear readynas as ftp server permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Netgear ReadyNAS as FTP-Server`}</h2>
    <p>{`The Netgear ReadyNAS can be used to send images or videos from the camera to the NAS via FTP and store them there in the event of an alarm. To set up the Netgear ReadyNAS, simply follow the instructions in the video. The setup only takes a few minutes.`}</p>
    <div className="flex-container">
    <div className="onecard">
 <a href="/de/Motion_Detection/Router_as_a_FTP_Server/Netgear_Ready_NAS_als_FTP_Server/Video/">
        <div className="image">
            <img alt="Netgear ReadyNAS as FTP Server" src="/de/images/Motion_Detection/NetgearReadyNAS_FTP_Upload.png" />
        </div>
    </a>
        <div className="action">
            <p>Netgear ReadyNAS as FTP-Server</p>
        </div>
    </div>
    </div>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      